import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './main.css'
import {VueMasonryPlugin} from 'vue-masonry';

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});

createApp(App).use(router).use(VueMasonryPlugin).mount('#app')
