import { createRouter, createWebHashHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import routes from './routes'

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
